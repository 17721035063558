import React from 'react';
import styled from 'styled-components';
import { appStoreLink, googlePlayLink } from '../utils/links';
import { media } from '../styles/mediaQueries';
import { isDesktop, isIOS, isAndroid } from 'react-device-detect';

import androidLogo from '../images/androidlogo.png';
import applelogo from '../images/applelogo.png';
import footerPhone from '../images/footerPhone.png';

const Footer = () => (
  <FooterContainer>
    <LeftContainer>
      <p>Télécharge l’application pour découvrir de délicieuses recettes IG bas, ultra simples et rapides à réaliser</p>
      <StoreButtonContainer>
        {isDesktop && (
          <>
            <AndroidButton href={googlePlayLink} target="blank">
              <img src={androidLogo} alt="Android logo"></img>
              <span>Download Android</span>
            </AndroidButton>
            <AppleButton href={appStoreLink} target="blank">
              <img src={applelogo} alt="Apple logo"></img>
              <span>Download iOS</span>
            </AppleButton>
          </>
        )}
        {isIOS && (
          <AppleButton href={appStoreLink} target="blank">
            <img src={applelogo} alt="Apple logo"></img>
            <span>Download iOS</span>
          </AppleButton>
        )}
        {isAndroid && (
          <AndroidButton href={googlePlayLink} target="blank">
            <img src={androidLogo} alt="Android logo"></img>
            <span>Download Android</span>
          </AndroidButton>
        )}
      </StoreButtonContainer>
    </LeftContainer>
    <Phone src={footerPhone}></Phone>
  </FooterContainer>
);

export default Footer;

const FooterContainer = styled.div`
  margin: 0 0 60px 0;
  padding: 20px;
  display: flex;
  justify-content: center;
  background-color: #fede4d;
  border-radius: 0;
  ${media.mobile`
  margin: 20px 0 0 0;
  border-radius: 12px;
  `}
  ${media.desktop`
  margin: 50px 0;
  padding: 0 40px;
  justify-content: space-between;
  `}
`;

const LeftContainer = styled.div`
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${media.desktop`
  align-items: flex-start;
`}

  > p {
    width: 100%;
    margin-bottom: 20px;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    ${media.desktop`
    text-align: left;
    width: 400px;
    font-size: 16px;
`}
`;

const StoreButtonContainer = styled.div`
  display: grid;
  max-width: 350px;
  grid-gap: 20px;
  grid-template-columns: auto auto;
  justify-content: center;
  ${media.desktop`
  justify-content: flex-start;
`}
`;

const AndroidButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 180px;
  height: 40px;
  background-color: black;
  border-radius: 8px;
  > span {
    font-size: 14px;
    color: white;
  }
  > img {
    height: 14px;
  }
`;

const AppleButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 150px;
  height: 40px;
  background-color: black;
  border-radius: 8px;
  > span {
    font-size: 14px;
    color: white;
  }
  > img {
    height: 14px;
  }
`;

const Phone = styled.img`
  display: none;
  margin: 20px 0 0 0;
  z-index: 2300;
  height: auto;
  width: 150px;
  ${media.desktop`
  display: block;
`}
`;
